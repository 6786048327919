/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, TitleMain, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, Image, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pb--16 pt--16" menu={true}>
            
            <Menu className="--full pb--06 pt--06" style={{"maxWidth":1540}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <TitleMain className="title-box fs--24 w--600 title-box--invert swpf--uppercase" content={"<span style=\"color: rgb(238, 77, 13);\">Půjčovna minibagrů</span><br>"}>
                </TitleMain>

                <Subtitle className="subtitle-box fs--20 subtitle-box--invert mt--02" content={"Plzeňský kraj"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 w--600 swpf--uppercase lh--16 pl--08 pr--06" innerClassName="pl--0 pr--0" href={"#technika-2"} target={""} content={"naše technika"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 w--600 swpf--uppercase lh--16 pl--08 pr--08" innerClassName="pl--0 pr--0" href={"#kontakt"} target={""} content={"kontaktovat"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--filling2 w--600 swpf--uppercase lh--16 pl--08 pr--08" innerClassName="pt--10 pb--12 pl--12 pr--12" href={"#poptat"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">poptat</span>"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-1bzlsgf --style4 --center bg--center --full" name={"uvod"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/279158c39c5b4015adf9c3221dbb1caa_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/279158c39c5b4015adf9c3221dbb1caa_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/279158c39c5b4015adf9c3221dbb1caa_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/279158c39c5b4015adf9c3221dbb1caa_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/279158c39c5b4015adf9c3221dbb1caa_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/279158c39c5b4015adf9c3221dbb1caa_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/279158c39c5b4015adf9c3221dbb1caa_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/279158c39c5b4015adf9c3221dbb1caa_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left mt--40" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--36 subtitle-box--invert mt--20" content={"Webové stránky na prodej – info@tvorba-stranek-brno.cz"}>
              </Subtitle>

              <Title className="title-box fs--102 title-box--invert" content={"Minibagry již od<br>1200 Kč / den.<br>"}>
              </Title>

              <Subtitle className="subtitle-box fs--36 subtitle-box--invert mt--20" content={"SLUŽBY ZASTAVENY<br>Půjčujeme zánovní minibagry a výkopovou techniku.&nbsp;"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--filling2 fs--22 swpf--uppercase mt--30" href={"#technika-2"} content={"<span style=\"color: rgb(255, 255, 255);\">NAŠE TECHNIKA</span><br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"technika"}>
          
          <ColumnWrap className="column__flex --left el--2 pb--20 pt--20 flex--center" anim={null} animS={null} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--left pl--25 pr--25" anim={null} animS={null} style={{"maxWidth":607}}>
              
              <Title className="title-box title-box--left pl--0 pr--0" style={{"maxWidth":690}} content={"Kopejte bez kompromisů s tou&nbsp;<span style=\"font-style: italic;\">kvalitnější </span>technikou na trhu."}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(238, 77, 13);\">Poskytujeme all-inclusive službu.</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":440}} content={"Přijedete, připojíme vlek a můžete vyrazit. Nemusíte se o nic starat – vše co potřebujete k práci je již součástí  připojené soupravy."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5 --left" anim={"4"} animS={"5"} style={{"maxWidth":1051}}>
              
              <Image alt={"Zánovní minibagr"} src={"https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_s=860x_.png"} svg={false} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_s=860x_.png 860w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_s=1400x_.png 1400w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pl--40 pr--40" name={"technika-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --left el--3 --full pb--20 pl--16 pr--16 pt--10" anim={"2"} animS={"5"} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Půjčovna minibagrů"} src={"https://cdn.swbpg.com/t/11212/7e89da5619d449a4911a0fefce3e0f9b_s=860x_.jpeg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/11212/7e89da5619d449a4911a0fefce3e0f9b_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/7e89da5619d449a4911a0fefce3e0f9b_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/7e89da5619d449a4911a0fefce3e0f9b_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/11212/7e89da5619d449a4911a0fefce3e0f9b_s=1400x_.jpeg 1400w"} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box" content={"Minibagry"}>
              </Subtitle>

              <Text className="text-box mt--02" style={{"maxWidth":467}} content={"<span style=\"color: rgb(238, 77, 13);\">od 1200 Kč / den.</span>"}>
              </Text>

              <Text className="text-box mt--16" style={{"maxWidth":467}} content={"Technika od 1 do 3 tun. Rypadla si můžete sami odvést přívěsem a kopat kde potřebujete – stačí vám klasický řidičák skupiny B."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 swpf--uppercase mt--25" href={"#poptat"} content={"Poptat"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Půjčovna nakladačů"} src={"https://cdn.swbpg.com/t/11212/0dd2b5673bc94508bb55276875a2f19b_e=498x0x1225x1633_s=660x_.jpeg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/11212/0dd2b5673bc94508bb55276875a2f19b_e=498x0x1225x1633_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/0dd2b5673bc94508bb55276875a2f19b_e=498x0x1225x1633_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/0dd2b5673bc94508bb55276875a2f19b_e=498x0x1225x1633_s=860x_.jpeg 860w"} position={{"x":"0%","y":"-3.2983508245877067%"}}>
              </Image>

              <Subtitle className="subtitle-box" content={"Minidumpery"}>
              </Subtitle>

              <Text className="text-box mt--02" style={{"maxWidth":467}} content={"<span style=\"color: rgb(238, 77, 13);\">od 1700 Kč / den.</span><br>"}>
              </Text>

              <Text className="text-box mt--16" style={{"maxWidth":467}} content={"Efektivní samonakládací minidumper a další. Poskytujeme také profesionální obsluhu – je na vás, jestli se spolehnete na sebe, nebo si necháte pomoci.\n"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 swpf--uppercase mt--25" href={"#poptat"} content={"Poptat"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Půjčovna výkopové techniky"} src={"https://cdn.swbpg.com/t/11212/648e3a602267485e971edc2414849879_e=0x280x1302x1734_s=660x_.jpeg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/11212/648e3a602267485e971edc2414849879_e=0x280x1302x1734_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/648e3a602267485e971edc2414849879_e=0x280x1302x1734_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/648e3a602267485e971edc2414849879_e=0x280x1302x1734_s=860x_.jpeg 860w"} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box" content={"Příslušenství"}>
              </Subtitle>

              <Text className="text-box mt--02" style={{"maxWidth":467}} content={"<span style=\"color: rgb(238, 77, 13);\">Individuálně.</span>"}>
              </Text>

              <Text className="text-box mt--16" style={{"maxWidth":467}} content={"Lžíce 20, 40 a 80 cm poskytujeme v základní ceně. Bržděný vleky s nosností až 1300 kg (skupina B), nebo zemní vrtáky v průměrech 10, 20 a 30 cm."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 swpf--uppercase mt--25" href={"#poptat"} content={"Poptat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-5owgzi --style3 bg--top --full --parallax pl--40 pr--40" name={"kontakt"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/3e88ce8b76824878a08edf64ebf35921_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/3e88ce8b76824878a08edf64ebf35921_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/3e88ce8b76824878a08edf64ebf35921_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/3e88ce8b76824878a08edf64ebf35921_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/3e88ce8b76824878a08edf64ebf35921_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/3e88ce8b76824878a08edf64ebf35921_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/3e88ce8b76824878a08edf64ebf35921_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/3e88ce8b76824878a08edf64ebf35921_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 --full pl--16 pr--16 flex--center" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4 --left pb--40 pl--40 pr--40 pt--40" anim={"7"} animS={"4"} style={{"maxWidth":1280,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box fs--30 lh--14" content={"Příslušenství, které jinde nenajdete.<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Půjčíme Vám i  příslušenství k minibagru, které jinde nenajdete. Vrtací  jednotku, hrábě, speciální lžíce, rovnací lištu, naklápěcí hydrosvahovky apod. Vše Vám urychlí a zpříjemní práci."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim7 --anim-s4 --left pb--40 pl--40 pr--40 pt--40" anim={"7"} animS={"4"} style={{"maxWidth":1280,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box fs--30 lh--14" content={"Poradíme vám.&nbsp;<br>Minibagry dostupné ihned.<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Dokážeme Vám poradit a vybrat nejlepší variantu pro Vaši realizaci. Řešíte akutní havárii nutně potřebujete minibagr? Jsme schopni zapůjčit minibagr v řádu několika hodin.\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim7 --anim-s4 --left pb--40 pl--40 pr--40 pt--40" anim={"7"} animS={"4"} style={{"maxWidth":1280,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box fs--30 lh--14" content={"Obsluhovat stroje vás samozřejmě naučíme.<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Každý stroj má svůj vlastní přepravní vlek. Nic nepřekládáme. K osvojení techniky práce pro Vás máme vyčleněný bagr na zemi na kterém Vás zaškolíme.\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"poptat"}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Poptávka"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":591}} content={"Poptejte nezávazně naše služby – půjčení minibagru a další. <br>Ozvěme e vám a na všem se dohodneme."}>
              </Text>

              <ContactForm className="--shape2 --style3 fs--20 w--300 lh--14 mt--30" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

              <Text className="text-box fs--16 lh--14 mt--12" style={{"maxWidth":477}} content={"Odesláním formuláře souhlasíte se zpracovaním osobních údajů. Budeme vás brzy kontaktovat!"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5" anim={"4"} animS={"5"}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/0420e21819114d1a9c32da6594d348c5_s=860x_.png"} svg={false} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/0420e21819114d1a9c32da6594d348c5_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/0420e21819114d1a9c32da6594d348c5_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/0420e21819114d1a9c32da6594d348c5_s=860x_.png 860w, https://cdn.swbpg.com/t/11212/0420e21819114d1a9c32da6594d348c5_s=1400x_.png 1400w, https://cdn.swbpg.com/t/11212/0420e21819114d1a9c32da6594d348c5_s=2000x_.png 2000w"} content={null}>
              </Image>

              <Text className="text-box text-box--right fs--16 lh--16 mt--50" style={{"maxWidth":""}} content={"Jsme specialisté&nbsp; v oblasti půjčení minibagru a výkopové techniky."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-874b7o --style2 bg--top --full --parallax" name={"kontakt"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/e9766fefaeff41b08d311af2dfcf2f73_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/e9766fefaeff41b08d311af2dfcf2f73_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/e9766fefaeff41b08d311af2dfcf2f73_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/e9766fefaeff41b08d311af2dfcf2f73_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/e9766fefaeff41b08d311af2dfcf2f73_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/e9766fefaeff41b08d311af2dfcf2f73_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/e9766fefaeff41b08d311af2dfcf2f73_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11212/e9766fefaeff41b08d311af2dfcf2f73_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"} style={{"maxWidth":1280}}>
              
              <Title className="title-box fs--72 title-box--invert" content={"Momentálně nedostupné"}>
              </Title>

              <Text className="text-box fs--18 text-box--invert lh--14 mt--02" content={"Odpovíme do 24 hod.<br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--30 subtitle-box--invert lh--14 mt--20" content={"Rádi vám s čímkoliv poradíme!<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"paticka"} style={{"backgroundColor":"rgba(18, 18, 18, 1)"}}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pl--25 pr--25 pt--10" style={{"maxWidth":1280}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--24 w--600 title-box--invert swpf--uppercase" content={"<span style=\"color: var(--color-variable-1);\">půjčovna minibagrů</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20 subtitle-box--invert mt--02" content={"Plzeňský kraj"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--08" content={"Služby nabízíme zejména pro oblasti:<br>"}>
              </Text>

              <Text className="text-box fs--16 lh--14 mt--02" content={"Plzeň, Příbram, Písek, Klatovy, Rokycany, Cheb, Karlovy Vary, Strakonice, České Budějovice, Tábor, Domažlice, Přeštice a další.<br>"}>
              </Text>

              <Text className="text-box fs--14 mt--0" content={"partneři: <a href=\"https://rentner.cz/autopujcovna-dodavek-ostrava/\" style=\"color: rgb(128, 128, 130);\">půjčovna dodávek Ostrava</a>,&nbsp;<a href=\"https://cistyfiltr.cz/\" style=\"color: rgb(128, 128, 130);\">čištění DPF</a>, <a href=\"https://alu-dvere.cz/hlinikove-vchodove-dvere/\" style=\"color: rgb(128, 128, 130);\">hliníkové dveře</a><span style=\"color: rgb(128, 128, 130);\">&nbsp;</span>, <a href=\"https://proncar.cz/pronajem-dodavek-ostrava/\" style=\"color: rgb(139, 139, 139);\">pronájem dodávek Ostrava</a>,&nbsp;<a href=\"https://www.dvere-simbera.cz/zarubne-skryte/\" style=\"color: rgb(172, 170, 170);\">Skryté zárubně</a><br><a href=\"https://saywebpage.com/cs/\">tvorba webových stránek</a>&nbsp;– Saywebpage.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"Hledáte rychlou realizaci prací menšího rozsahu u Vás doma? Nechce se Vám už čekat na dlouhé objednací termíny? Půjčte si špičkový minibagr. Bez kaucí a bez komplikací. Pro lidi.<br><br>"}>
              </Text>

              <Text className="text-box fs--14 mt--0" content={"TIP –&nbsp;<a href=\"https://saywebpage.com/cs/svatebni-web/\">vytvořit svatební web</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"#uvod"} content={"Úvod"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"#technika-2"} content={"Naše technika"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"#kontakt"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}